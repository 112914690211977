.header {
  height: 95vh;
  background-size: cover;
  background-position: top;
  position: relative;
  overflow: hidden;

  &__box {
    color: $color-white;
    position: absolute;
    top: 15%;
    left: 0;
    padding: 0 3rem;
    height: 80%;
    width: 100%;

    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    justify-content: space-evenly;

    &--top {
      top: 5% !important;
    }
  }

  &__paragraph {
    animation-name: moveInRight;
    animation-duration: 1s;
    animation-timing-function: ease-out;
  }
}
