.blog {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond(tab-port) {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__item {
    text-align: center;
    border-radius: 2px;
    padding: 1rem;
    margin: 1rem;

    width: 40rem;
    height: 25rem;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    transition: all 0.2s;

    &:hover {
      cursor: pointer;
      transform: scale(1.01);
    }

    @include respond(tab-port) {
      width: 30rem;
      height: 20rem;
    }
  }
  // background-color: $cohere_orange;

  // text-align: center;
  // border-radius: 2px;
  // padding: 1rem;
  // margin: 1rem 0;
  // width: 35rem;
  // width: 39rem;
  // width: 40rem;
  // height: 25rem;

  // display: flex;
  // flex-direction: column;
  // justify-content: space-around;

  &__img {
    width: 100%;
    // height: 100%;
    height: 20rem;
    object-fit: cover;

    @include respond(tab-port) {
      height: 15rem;
    }
  }

  &__title {
    font-size: 1.6rem !important;
    font-weight: bold;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
