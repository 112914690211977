.u-full-width-img {
  width: 100%;
  object-fit: cover;
}

.u-text-center {
  text-align: center;
}

.u-margin-center {
  margin: 0 auto;
}

.u-mt-small {
  margin-top: 3rem;
}

.u-default-text {
  color: $color-white;
  font-size: 1.7rem;
  font-family: $font-spacegrotesk;
  text-align: center;
  line-height: 1.7rem;
  font-weight: bold;
  backface-visibility: hidden;
}

.u-cohere-orange {
  color: $cohere_orange;
}
