.slider {
  max-width: 100vw;
  // min-height: 85vh;
  height: 85vh;
  position: relative;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  width: 100%;
  // max-width: 100vw;
  height: 100%;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;

  &__active {
    opacity: 1;
  }
}

.btn-slide {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: #f1f1f171;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }

  & img {
    width: 2.5rem;
    height: 2.5rem;
    pointer-events: none;
  }

  &--prev {
    top: 50%;
    left: 2rem;
    transform: translateY(-60%);
  }
  &--next {
    top: 50%;
    right: 2rem;
    transform: translateY(-60%);
  }
}

.container-dots {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.dot {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin: 0 5px;
  background: #f1f1f1;

  &__active {
    background: rgb(32, 32, 32);
  }
}
