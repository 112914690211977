.navigation {
  &__nav {
    height: 12vh;
    background-color: $cohere-blue;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    padding-left: 3rem;
    height: 5rem;
    width: 10rem;
    transition: all 0.2s;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }

  &__list {
    list-style: none;
    display: flex;
    // padding-right: 3rem;
    // flex-grow: 0.2; /*change this*/

    align-items: center;
    justify-content: space-between;

    @include respond(tab-port) {
      display: none;
    }
  }

  &__item {
    margin: 2.5rem;
    transition: all 0.2s;
    z-index: 11;
    position: relative;

    &:hover {
      cursor: pointer;
      transform: scale(1.05) translateY(-0.3rem);
    }

    &:active,
    &:focus {
      transform: scale(1) translateY(0.3rem);
    }

    &:hover ul,
    &:focus ul {
      opacity: 1;
      visibility: visible;
    }

    &drop {
      position: absolute;
      font-size: $default-font-size;
      font-family: $font-spacegrotesk;
      font-weight: bold;
      top: 4rem;
      left: -1rem;
      box-shadow: 0 0 10px $color-grey-dark-3;
      background-color: white;
      border-radius: 0.2rem;
      // width: 12rem;
      width: auto;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      opacity: 0;
      visibility: hidden;
      transition: opacity 200ms ease-in-out;

      &--link {
        padding: 0.5rem 1rem;
        border-radius: 0.2rem;
        text-decoration: none;
        color: $color-black;
        transition: background-color 200ms ease-in-out;
      }

      li {
        list-style: none;

        &:hover,
        &:focus {
          background-color: $cohere-blue;
        }
      }

      @include respond(tab-port) {
        top: 0;
        left: 50%;
      }
    }
  }

  &__link {
    transition: all 0.2s;

    &:link,
    &:visited {
      text-decoration: none;
      font-size: 2.5rem;

      color: $color-white;
      font-weight: 400;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }

  &__btn {
    font-size: 5rem;
    margin-right: 3rem;
    color: $cohere-black;
    display: none;
    transition: all 0.2s;

    &:hover {
      cursor: pointer;
      transform: scale(1.05) translateY(-0.3rem);
    }

    @include respond(tab-port) {
      display: block;
    }
  }
}
