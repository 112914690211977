@font-face {
  font-family: "Morganite";
  src: url(../../fonts/Morganite-Bold.ttf) format("truetype");
}

h1 {
  font-family: $font-morganite;
  font-size: 10rem;
}

h2 {
  font-family: $font-morganite;
  font-size: 6.25rem;
}

h3 {
  font-family: $font-morganite;
  font-size: 4.4rem;
}

h4 {
  font-family: $font-spacegrotesk;
  font-size: 2.4rem !important;
}

p {
  font-family: $font-spacegrotesk;
  font-size: 2rem !important;
}

button {
  font-family: $font-spacegrotesk;
}

body {
  font-family: $font-spacegrotesk;
  font-weight: 400;
  line-height: 1.7;
}

.heading-primary {
  color: $color-white;
  text-transform: uppercase;
  backface-visibility: hidden;
  // margin-bottom: 6rem;

  &--main {
    display: block;
    font-size: 10rem;
    font-weight: 400;
    line-height: 10rem;

    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;

    @include respond(phone) {
      font-size: 7rem;
      line-height: 6rem;
    }
  }

  &--sub {
    color: $color-white;
    font-size: 9rem;
    text-decoration: none;
  }
}

.paragraph {
  color: $color-white;
  font-size: $default-font-size;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  &--black {
    color: inherit;
  }

  &--small {
    color: inherit;
    font-size: 1.4rem !important;
  }

  &--medium {
    color: inherit;
    font-size: 1.7rem !important;
  }

  &--capital {
    text-transform: uppercase;
  }
}
