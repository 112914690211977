*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; // 1rem = 10px 10px/16px = 62.5%
  box-sizing: border-box;
  scroll-behavior: smooth;

  // width < 1200?
  @include respond(tab-land) {
    font-size: 56.25%; // 1rem = 9px 9px/16px
  }

  // width < 900?
  @include respond(tab-port) {
    font-size: 50%; // 1rem = 8px 8px/16px
  }

  @include respond(big-desktop) {
    font-size: 75%; // 1rem = 12px 12px/16px
  }
}
