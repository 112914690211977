.testimonial {
  height: 100%;
  width: 100%;
  // max-width: 100vw;
  display: flex;
  align-items: center;

  &__img {
    height: 100%;
    // width: 100%;
    width: 50vw;

    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
  }

  &__text {
    // width: 100%;
    width: 50vw;

    height: 100%;
    color: white;
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--paragraph {
      flex: 1 1 0;
      min-height: 0;
      font-size: 1.05vw !important;

      @include respond(tab-port) {
        font-size: 1.5rem !important;
      }
    }
  }
}
