.halfsection {
  // height: 100%;
  height: 85vh;
  max-width: 100vw;
  overflow: hidden;

  display: flex;

  &__img {
    height: 100%;
    // width: 100%;
    width: 50vw;

    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;

    @include respond(tab-port) {
      display: none;
    }
  }

  &__text {
    height: 100%;
    // width: 100%;
    width: 50vw;

    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond(tab-port) {
      align-items: center;
      width: 100%;
    }

    & .chevron {
      @include respond(tab-port) {
        display: none;
      }
    }
  }
}
