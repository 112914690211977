.section-video-embed {
  background-color: $cohere_black;
  min-height: 65vh;

  &--video {
    margin: 0 auto;
    padding: 3rem 0rem;
    height: 88vh;
    width: 100%;

    @include respond(tab-port) {
      height: 50vh;
    }
  }
}

.section-what-we-do {
  background-color: $cohere_red;
  min-height: 75vh;
}

.section-impact {
  background-color: #f7f7f7;
  // min-height: 85vh;
  min-height: 95vh;
}

.section-we-are-cohere {
  background-color: $cohere_orange;
  min-height: 75vh;

  &--image {
    height: 10rem;
    width: 20rem;
    padding: 1rem;

    background-size: cover;
    background-position: top;
    position: relative;
    overflow: hidden;

    @include respond(tab-port) {
      display: none;
    }
  }
}

.section-stories {
  background-color: $cohere_red;
  min-height: 75vh;
}

.section-smashing-cycle {
  background-color: $cohere_blue;
  min-height: 75vh;
}

.section-reframe {
  background-color: $cohere_red;
  min-height: 75vh;
}

.section-news {
  background-color: $cohere_black;
  min-height: 75vh;
}

.section-jobs {
  background-color: #f7f7f7;

  min-height: 75vh;
}

//TODO: Put this in its own page
.section-careers {
  background-color: $cohere_black;
  min-height: 85vh;
  padding: 5rem;
}
