// COLOURS
$cohere_blue: #72ccca;
$cohere_red: #ff6865;
$cohere_black: #282828;
$cohere_orange: #ff9343;

$color-white: #fff;
$color-black: #000;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$font-morganite: "Morganite", sans-serif;
$font-spacegrotesk: "Space Grotesk", sans-serif;

// FONT
$default-font-size: 1.6rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;

// TIMELINES
$timeline-body-round: 3px;
$timeline-body-shadow: 1px 3px 9px rgba(0, 0, 0, 0.1);
